import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParam } from "react-use";
import { Audio } from "react-loader-spinner";
import { Box, Flex } from "@100mslive/roomkit-react";
import Preview from "./Preview";
import { ErrorDialog } from "../primitives/DialogContent";
import { AppContext } from "./context/AppContext";
import { Fingerprint } from "./Fingerprint/fingerprint";
import { Text } from "../react-ui/src";

import {
  QUERY_PARAM_SKIP_PREVIEW_HEADFUL,
  QUERY_PARAM_SKIP_PREVIEW,
  QUERY_PARAM_AUTH_TOKEN,
  UI_SETTINGS,
} from "../common/constants";
import { getToken } from "../services/tokenService";
import { useSetUiSettings } from "./AppData/useUISettings";
import BTHS from "../images/bths.gif";
/**
 * query params exposed -
 * skip_preview=true => used by recording and streaming service, skips preview and directly joins
 *                      header and footer don't show up in this case
 * skip_preview_headful=true => used by automation testing to skip preview without impacting the UI
 * name=abc => gives the initial name for the peer joining
 * auth_token=123 => uses the passed in token to join instead of fetching from token endpoint
 * ui_mode=activespeaker => lands in active speaker mode after joining the room
 */

const env = process.env.REACT_APP_ENV;

const BBPreviewScreen = ({ getUserToken, room, role, roomName }: any) => {
  const navigate = useNavigate();
  const { tokenEndpoint } = useContext(AppContext);
  const fingerprint =
    useContext(Fingerprint) || "abcdef1234567890abcdef1234567890";
  const [, setIsHeadless] = useSetUiSettings(UI_SETTINGS.isHeadless);
  const { roomId: urlRoomId, role: userRole } = useParams(); // from the url
  const [token, setToken] = useState("");
  const [error, setError] = useState({ title: "", body: <></> });
  // way to skip preview for automated tests, beam recording and streaming
  const beamInToken = useSearchParam("token") === "beam_recording"; // old format to remove

  // Preview will never be skipped for this campaign
  let skipPreview: string | boolean | null =
    useSearchParam(QUERY_PARAM_SKIP_PREVIEW) === "true";
  // use this field to join directly for quick testing while in local
  const directJoinHeadfulFromEnv =
    process.env.REACT_APP_HEADLESS_JOIN === "true";
  const directJoinHeadful =
    useSearchParam(QUERY_PARAM_SKIP_PREVIEW_HEADFUL) === "true" ||
    directJoinHeadfulFromEnv;
  skipPreview = skipPreview || beamInToken || directJoinHeadful;
  const initialName =
    window.localStorage.getItem("username") || (skipPreview ? "Beam" : "");
  let authToken = useSearchParam(QUERY_PARAM_AUTH_TOKEN);

  useEffect(() => {
    if (authToken) {
      setToken(authToken);
      return;
    }
    /**
     * User needs a token to be in the room, regardless of role.
     */
    const getTokenFn =
      !userRole && !role
        ? () => getUserToken(room)
        : () => getToken(fingerprint, role || userRole, room || urlRoomId);
    getTokenFn()
      .then((token: any) => {
        setToken(token);
      })
      .catch((error: any) => {
        setError(convertPreviewError(error));
      });
  }, [
    tokenEndpoint,
    urlRoomId,
    getUserToken,
    userRole,
    authToken,
    role,
    room,
    fingerprint,
  ]);
  const onJoin = () => {
    !directJoinHeadful &&
      typeof skipPreview !== "string" &&
      setIsHeadless(skipPreview);
    if (
      window.location.pathname.endsWith("/live") ||
      window.location.pathname.endsWith("/live/")
    ) {
      return;
    }
    let meetingURL;
    if (window.location.pathname.endsWith("/")) {
      meetingURL = window.location.pathname + "live";
    } else {
      meetingURL = window.location.pathname + "/live";
    }
    console.log("meeting URL" + meetingURL);
    navigate(meetingURL);
  };

  if (error.title) {
    return <ErrorDialog title={error.title}>{error.body}</ErrorDialog>;
  }
  return (
    <Flex
      direction="column"
      css={{ size: "100%", alignItems: "center", justifyContent: "stretch" }}
    >
      <Text
        css={{
          fontFamily: "Brandon_Grotesque !important",
          textTransform: "uppercase",
          fontSize: "14px",
          fontWeight: "bold",
          color: "$textPrimary",
          margin: "$0",
        }}
      >
        Brooklyn Tech HS Nov 16th
      </Text>
      <Box
        css={{
          width: "52%",
          maxWidth: "175px",
          "& > img": {
            width: "100%",
            borderRadius: "$1",
          },
        }}
      >
        <img src={BTHS} alt="Brooklyn Tech High School 10/19" />
      </Box>
      <Text
        css={{
          fontFamily: "Brandon_Grotesque !important",
          fontSize: "14px",
          color: "white",
          margin: "10px",
        }}
      >
        SubWire invites you to join the game day community.
      </Text>
      <Box
        css={{
          maxWidth: "350px",
          "& > img": {},
        }}
      ></Box>
      {/* If the user is admin or stage, render preview box. */}
      <Box
        css={{
          maxWidth: "350px",
          "& > img": {},
        }}
      >
        {token ? (
          <Preview
            initialName={initialName}
            skipPreview={skipPreview}
            env={env}
            onJoin={onJoin}
            token={token}
            roomName={roomName}
          />
        ) : (
          <Audio
            height="100"
            width="100"
            color="#F5C950"
            ariaLabel="audio-loading"
            wrapperStyle={{}}
            wrapperClass="wrapper-class"
            visible={true}
          />
        )}
      </Box>
    </Flex>
  );
};

const convertPreviewError = (error: any) => {
  console.error("[error]", { error });
  if (error.response && error.response.status === 404) {
    return {
      title: "Room does not exist",
      body: ErrorWithSupportLink(
        "We could not find a room corresponding to this link."
      ),
    };
  } else if (error.response && error.response.status === 403) {
    return {
      title: "Accessing room using this link format is disabled",
      body: ErrorWithSupportLink(
        "You can re-enable this from the developer section in Dashboard."
      ),
    };
  } else {
    console.error("Token API Error", error);
    return {
      title: "Error fetching token",
      body: ErrorWithSupportLink(
        "An error occurred while fetching the app token. Please look into logs for more details."
      ),
    };
  }
};

export const ErrorWithSupportLink = (errorMessage: any) => (
  <div>
    {errorMessage} Something went wrong. Please take a screenshot of this error
    and let us know what happened at{" "}
    <a href="mailto:info@subwire.io?subject=error">info@subwire.io</a>
  </div>
);

export default BBPreviewScreen;
