import { createContext, useEffect, useState } from "react";

import { load } from "@fingerprintjs/fingerprintjs";

import { registerFingerprint } from "../../common/utils";

const Fingerprint = createContext("");
/**
 * Generates a unique fingerprint using FingerprintJS
 * @returns visitorId
 */
async function createFingerprint() {
  const fp = await load();
  const { visitorId } = await fp.get();

  return visitorId;
}

const FingerprintProvider = ({ children }: any) => {
  const [fingerprint, setFingerprint] = useState("");

  const getFingerprint = async () => {
    const fp = await createFingerprint();
    setFingerprint(fp);
    // Register  with back-end
    registerFingerprint(fp);
  };

  useEffect(() => {
    getFingerprint();
  }, []);

  // Send fingerprint to hotjar
  useEffect(() => {
    if (window.hj !== undefined) {
      window.hj("identify", fingerprint, {
        Registered: new Date().toISOString(),
      });
    }
  }, [fingerprint]);

  return (
    <>
      <Fingerprint.Provider value={fingerprint}>
        {children}
      </Fingerprint.Provider>
    </>
  );
};

const FingerprintConsumer = Fingerprint.Consumer;
export { Fingerprint, FingerprintProvider, FingerprintConsumer };
