import React from "react";
import { Box, Flex, HorizontalDivider } from "@100mslive/roomkit-react";
import { Button, Text } from "../react-ui/src";
import PlaceholderBg from "../images/emptystage.jpg";
import Logo from "../images/logo_no_tagline_1375_475.png";

const socialDisplayName = "@subwire.io";
const socialSlug = "subwire.io";

const PostLeave = ({ vanityUrl, role }: any) => {
  return (
    <Flex justify="center" align="center" css={{ size: "100%", bg: "$mainBg" }}>
      <Box
        css={{
          position: "relative",
          overflow: "hidden",
          w: "37.5rem",
          maxWidth: "80%",
          h: "75%",
          maxHeight: "42.5rem",
          r: "$3",
          m: "0 auto",
          backgroundImage: `url(${PlaceholderBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "33%",
          "@md": {
            width: "100%",
            height: "100%",
            maxWidth: "unset",
            maxHeight: "unset",
          },
        }}
      >
        <Flex
          align="center"
          direction="column"
          css={{
            position: "absolute",
            w: "100%",
            top: 0,
            left: 0,
            pt: "20%",
            textAlign: "center",
            background:
              "linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)",
          }}
        >
          <img src={Logo} alt="Brand logo" style={{ maxWidth: "75%" }} />
          <Text
            color="white"
            css={{
              variant: "h5",
              mt: "$8",
              fontWeight: "$semiBold",
            }}
          >
            Thanks for joining today's community livestream.
          </Text>
          <HorizontalDivider
            css={{ bg: "$textPrimary", maxWidth: "50%", m: "$10 0" }}
          />
          <Flex justify="center" direction="column">
            <Button
              onClick={() => {
                window.open(`https://instagram.com/${socialSlug}`, "_blank");
              }}
              css={{ m: "$4", color: "$black" }}
              data-testid="go_to_dashboard_btn"
            >
              Follow {socialDisplayName}
            </Button>
            <Button
              onClick={() => {
                window.open(
                  `${window.location.protocol}//${window.location.hostname}:${
                    window.location.port
                  }/${vanityUrl}${role ? role : ""}`
                );
              }}
              css={{ m: "$4", color: "$black" }}
              data-testid="join_again_btn"
            >
              Join Room Again
            </Button>
            <Button
              onClick={() => {
                let urlParams = "?utm_source=webapp";
                if (window.location.search !== "") {
                  urlParams += window.location.search.replace("?", "&"); // append any existing url params
                }
                window.open(`https://www.subwire.io/${urlParams}`);
              }}
              css={{ m: "$4", color: "$black" }}
              data-testid="return_home_btn"
            >
              Return Home
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default PostLeave;
