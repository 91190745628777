type FetchOptions = RequestInit & { retryCount?: number };

const MAX_RETRIES = 4;

/**
 * Fetches with retry logic.
 * @param {RequestInfo} url - The endpoint URL
 * @param {FetchOptions} options - Fetch options including method, headers, body
 * @returns {Promise<Response>}
 */
const fetchWithRetry = async (
  url: RequestInfo,
  options: FetchOptions
): Promise<Response> => {
  let error: Error | string = "";

  for (let i = 0; i < (options.retryCount || MAX_RETRIES); i++) {
    try {
      return await fetch(url, options);
    } catch (err: any) {
      error = err;
    }
  }

  console.error("Fetch failed after max retries", { url, options });
  throw error;
};

/**
 * Constructs the backend API endpoint based on the current environment.
 * @returns {string} - The backend API endpoint URL
 */
export function getBackendEndpoint(): string {
  const baseDomain = window.location.hostname;
  const env = process.env.REACT_APP_ENV;

  if (baseDomain === "qa2.100ms.live" || env === "qa") {
    return (
      process.env.REACT_APP_QA_BACKEND_API || "https://qa-in.100ms.live/hmsapi/"
    );
  } else if (baseDomain === "prod2.100ms.live" || env === "prod") {
    return (
      process.env.REACT_APP_PROD_BACKEND_API ||
      "https://prod-in.100ms.live/hmsapi/"
    );
  } else {
    return (
      process.env.REACT_APP_BACKEND_API || "https://prod-in.100ms.live/hmsapi/"
    );
  }
}

/**
 * Extracts the room code from the URL path.
 * @returns {string} - The extracted room code
 */
const extractUrlCode = (): string => {
  const path = window.location.pathname;
  let roomCode = "";

  if (path.startsWith("/subwire")) {
    roomCode = process.env.CURRENT_ROOM_ID || "6441ba51e46ec319b1eb54d9";
  } else if (path.startsWith("/preview/") || path.startsWith("/meeting/")) {
    roomCode = path.slice(9).split("/")[0] || "";
  }

  return roomCode.trim();
};

/**
 * Retrieves a user token.
 * @param {string} userId - The user ID
 * @param {string} role - The user's role
 * @param {string} roomId - The room ID
 * @returns {Promise<string>} - The token
 */
export async function getToken(
  userId: string,
  role: string,
  roomId: string
): Promise<string> {
  const endpoint =
    "https://prod-in2.100ms.live/hmsapi/bths-livestream.app.100ms.live/api/token";

  const response = await fetchWithRetry(endpoint, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ role, room_id: roomId, user_id: userId }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Request failed! ${errorText}`);
  }

  const { token, msg } = await response.json();
  if (!token) {
    throw new Error(msg || "Token is null");
  }

  return token;
}

/**
 * Retrieves the user token for a specific user.
 * @param {string} userName - The user's name or ID
 * @returns {Promise<string>} - The user token
 */
export async function getUserToken(userName: string): Promise<string> {
  const code = extractUrlCode();
  const url = `${getBackendEndpoint()}get-token`;

  const headers = {
    "Content-Type": "application/json",
    subdomain: "https://prod-in2.100ms.live/hmsapi",
  };

  const response = await fetchWithRetry(url, {
    method: "POST",
    headers,
    body: JSON.stringify({ code, user_id: userName }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Request failed! ${errorText}`);
  }

  const { token } = await response.json();
  return token;
}
