import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import {
  HMSRoomProvider,
  selectLocalPeerID,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Box, ThemeTypes } from "@100mslive/roomkit-react";
import { AppContextProvider } from "./components/context/AppContext";
import { FeatureFlags } from "./services/FeatureFlags";
import { FingerprintProvider } from "./components/Fingerprint/fingerprint";
import {
  getUserToken as defaultGetUserToken,
  getBackendEndpoint,
} from "./services/tokenService";
import getVanityUrls from "./services/vanityUrlService";
import "./base.css";
import "./index.css";
// @ts-ignore
import Logo from "./images/logo_no_tagline_1375_475.png";
import FullPageProgress from "./components/FullPageProgress";
import { KeyboardHandler } from "./components/Input/KeyboardInputManager";
import PostLeave from "./components/PostLeave";
import { AppData } from "./components/AppData/AppData";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { HMSThemeProvider } from "./react-ui/src";
import { AppRoutesProps } from "./@types";
import BBPreviewScreen from "./components/BBPreviewScreen";

const Conference = React.lazy(() => import("./components/conference"));
const PreviewScreen = React.lazy(() => import("./components/PreviewScreen"));
const ErrorPage = React.lazy(() => import("./components/ErrorPage"));

const defaultTokenEndpoint = process.env
  .REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
  ? `${getBackendEndpoint()}${
      process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
    }/`
  : process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT || "";

const envPolicyConfig = JSON.parse(process.env.REACT_APP_POLICY_CONFIG || "{}");
const bthsRoomId = "67277296849b17dcfc918cc3";
const ngrok_rooms = {
  alex: { roomId: "6408c0d85effce434ac7d6f3" },
  miketerry: { roomId: bthsRoomId },
  localcreators: { roomId: "65b97ea0517d71d91b155146" },
  bths: { roomId: bthsRoomId },
};

let roomName = "";
if (window.location.host.includes("localhost")) {
  roomName = window.location.pathname.split("/")[1].split("-")[0] || "Mike";
} else {
  roomName = window.location.pathname.split("/")[1].split("-")[0] || "Mike";
}

roomName =
  roomName === "leave"
    ? window.location.pathname.split("/")[2] || "Mike"
    : roomName;

document.title = `${roomName}'s ${document.title}`;

const getAspectRatio = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  const host = process.env.REACT_APP_HOST_NAME || window.location.hostname;
  const portraitDomains = (
    process.env.REACT_APP_PORTRAIT_MODE_DOMAINS || ""
  ).split(",");
  if (portraitDomains.includes(host) && width > height) {
    return { width: height, height: width };
  }
  return { width, height };
};

export function EdtechComponent({
  roomId = "",
  tokenEndpoint = defaultTokenEndpoint,
  themeConfig: {
    aspectRatio = "1-1",
    font = "Arial",
    color = "#F5C950",
    theme = ThemeTypes.default,
    logo = "",
    headerPresent = "false",
    metadata = "",
  },
  getUserToken = defaultGetUserToken,
  policyConfig = envPolicyConfig,
}) {
  const { 0: width, 1: height } = aspectRatio
    .split("-")
    .map((el: string) => parseInt(el));
  const [themeType, setThemeType] = useState(theme);
  const [urls, setUrls] = useState<SubwireRooms>();
  interface SubwireRooms {
    [name: string]: {
      roomId: string;
      venmo?: string;
      fingerprint?: string;
      created_at?: number;
    };
  }

  useEffect(() => {
    if (window.location.hostname.includes("ngrok")) {
      setUrls(ngrok_rooms);
    } else {
      getVanityUrls(process.env.VANITY_URL_ENDPOINT).then(vanityUrls => {
        setUrls(vanityUrls);
      });
    }
  }, []);

  useEffect(() => {
    setThemeType(theme);
  }, [theme]);

  return (
    <ErrorBoundary>
      <FingerprintProvider>
        <HMSRoomProvider isHMSStatsOn={FeatureFlags.enableStatsForNerds}>
          <HMSThemeProvider
            themeType={"dark"}
            aspectRatio={getAspectRatio({ width, height })}
            theme={{
              colors: {
                // @ts-expect-error
                brandLight: "#e8c972",
                // @ts-expect-error
                brandDefault: "#F5C950",
                // @ts-expect-error
                brandDark: "#90752d",
                // @ts-expect-error
                brandDisabled: "#90752d",
              },
              fonts: {
                // @ts-expect-error
                sans: ["$sans", "Inter", "sans-serif"],
              },
            }}
          >
            <AppContextProvider
              roomId={roomId}
              tokenEndpoint={tokenEndpoint}
              policyConfig={policyConfig}
              appDetails={metadata}
              logo={logo || Logo}
            >
              <Box
                css={{
                  bg: "$mainBg",
                  w: "100%",
                  ...(headerPresent === "true"
                    ? { flex: "1 1 0", minHeight: 0 }
                    : { h: "100%" }),
                }}
              >
                {urls && (
                  <AppRoutes
                    getUserToken={getUserToken}
                    appDetails={metadata}
                    urls={urls}
                  />
                )}
              </Box>
            </AppContextProvider>
          </HMSThemeProvider>
        </HMSRoomProvider>
      </FingerprintProvider>
    </ErrorBoundary>
  );
}

const defaultRole = "viewer";
const defaultVenmo = "subwireceo";

function AppRoutes({ getUserToken, appDetails, urls }: AppRoutesProps) {
  const localPeerID = useHMSStore(selectLocalPeerID);
  const { roomId } = useParams();

  return (
    <Router>
      <AppData appDetails={appDetails} />
      <KeyboardHandler />
      <Routes>
        <Route
          path="/bths-on-stage"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="stage"
                roomName="BTHS"
              />
            </Suspense>
          }
        />
        <Route
          path="/bths-reduced"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="stage-reduced"
                roomName="BTHS"
              />
            </Suspense>
          }
        />
        <Route
          path="/bths-admin"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="admin"
                roomName="BTHS"
              />
            </Suspense>
          }
        />
        <Route
          path="/bths"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="viewer"
                roomName="BTHS"
              />
            </Suspense>
          }
        />
        <Route
          path="/miketerry-on-stage"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="stage"
                roomName="BTHS"
              />
            </Suspense>
          }
        />
        <Route
          path="/miketerry"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="viewer"
                roomName="BTHS"
              />
            </Suspense>
          }
        />
        <Route
          path={`/bths/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"viewer"}
                venmoHandle={"subwireceo"}
                vanityUrl={"BTHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/bths-on-stage/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"stage"}
                venmoHandle={"subwireceo"}
                vanityUrl={"BTHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/bths-reduced/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"stage-reduced"}
                venmoHandle={"subwireceo"}
                vanityUrl={"BTHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/bths-admin/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"admin"}
                venmoHandle={"subwireceo"}
                vanityUrl={"BTHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/miketerry/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"viewer"}
                venmoHandle={"subwireceo"}
                vanityUrl={"BTHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/miketerry-on-stage/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"stage"}
                venmoHandle={"subwireceo"}
                vanityUrl={"BTHS"}
              />
            </Suspense>
          }
        />

        <Route
          path={`/${roomName}`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen
                getUserToken={getUserToken}
                room={urls[roomName]?.roomId}
                role={defaultRole}
                roomName={roomName}
              />
            </Suspense>
          }
        />
        <Route
          path={`/${roomName}-backstage`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen
                getUserToken={getUserToken}
                room={urls[roomName]?.roomId}
                role="backstage"
                roomName={roomName}
              />
            </Suspense>
          }
        />
        <Route
          path={`/${roomName}-on-stage`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen
                getUserToken={getUserToken}
                room={urls[roomName]?.roomId}
                role="stage"
                roomName={roomName}
              />
            </Suspense>
          }
        />
        <Route
          path={`/${roomName}/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={urls[roomName]?.roomId}
                userRole={defaultRole}
                venmoHandle={urls[roomName]?.venmo || defaultVenmo}
                vanityUrl={roomName}
              />
            </Suspense>
          }
        />
        <Route
          path={`/${roomName}-backstage/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={urls[roomName]?.roomId}
                userRole="backstage"
                venmoHandle={urls[roomName]?.venmo || defaultVenmo}
                vanityUrl={roomName}
              />
            </Suspense>
          }
        />
        <Route
          path={`/${roomName}-on-stage/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={urls[roomName]?.roomId}
                userRole="stage"
                venmoHandle={urls[roomName]?.venmo || defaultVenmo}
                vanityUrl={roomName}
              />
            </Suspense>
          }
        />
        <Route
          path={`/leave/${roomName}/stage`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PostLeave vanityUrl={roomName} role="-on-stage" />
            </Suspense>
          }
        />
        <Route
          path={`/leave/${roomName}/backstage`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PostLeave vanityUrl={roomName} role="backstage" />
            </Suspense>
          }
        />
        <Route
          path={`/leave/${roomName}/viewer`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PostLeave vanityUrl={roomName} />
            </Suspense>
          }
        />
        <Route
          path="/leave/*"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PostLeave vanityUrl={roomName} />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <ErrorPage error="Invalid URL!" />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

export default function App() {
  return (
    <HMSRoomProvider isHMSStatsOn={FeatureFlags.enableStatsForNerds}>
      {" "}
      <EdtechComponent
        themeConfig={{
          aspectRatio: process.env.REACT_APP_TILE_SHAPE,
          theme:
            process.env.REACT_APP_THEME === "light"
              ? ThemeTypes.default
              : ThemeTypes.default,
          color: process.env.REACT_APP_COLOR,
          logo: process.env.REACT_APP_LOGO,
          font: process.env.REACT_APP_FONT,
          headerPresent: process.env.REACT_APP_HEADER_PRESENT,
          metadata: process.env.REACT_APP_DEFAULT_APP_DETAILS, // A stringified object in env
        }}
        getUserToken={defaultGetUserToken}
      />
    </HMSRoomProvider>
  );
}
