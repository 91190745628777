import { useState, useEffect, useCallback } from "react";
import {
  usePreviewJoin,
  useAVToggle,
  useDevices,
  useHMSActions,
} from "@100mslive/react-sdk";
import { CheckIcon } from "@100mslive/react-icons";

import { Input, Flex, Checkbox, styled } from "@100mslive/roomkit-react";
import { Button, Text } from "../react-ui/src";
import { PreviewTile } from "./PreviewTile";
import {
  UserPreferencesKeys,
  useUserPreferences,
} from "./hooks/useUserPreferences";

const defaultPreviewPreference = {
  name: "",
  isAudioMuted: false,
  isVideoMuted: false,
};

const isValidEmail = (email: string) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const Preview = ({ token, onJoin, env, skipPreview, initialName }: any) => {
  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );
  const [name, setName] = useState(initialName || previewPreference.name);
  const { allDevices, selectedDeviceIDs } = useDevices();

  const { videoInput } = allDevices;
  const hmsActions = useHMSActions();
  let deviceIds = videoInput?.filter(
    info => info?.label?.includes("back") || info?.label?.includes("Back")
  );
  if (!deviceIds || deviceIds.length === 0) {
    deviceIds = videoInput;
  }
  const defaultCamera =
    deviceIds?.length && videoInput?.length
      ? deviceIds[0]?.deviceId || videoInput[0]?.deviceId
      : typeof videoInput === "string"
      ? videoInput
      : "";
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
  const { enableJoin, preview, join } = usePreviewJoin({
    name,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: skipPreview ? true : previewPreference.isAudioMuted,
      isVideoMuted: skipPreview ? true : previewPreference.isVideoMuted,
      videoDeviceId: defaultCamera,
    },
    captureNetworkQualityInPreview: true,
  });

  const savePreferenceAndJoin = useCallback(() => {
    window.localStorage.setItem("username", name);
    setPreviewPreference({
      name,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });
    join();
    onJoin && onJoin();
  }, [
    setPreviewPreference,
    name,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    join,
    onJoin,
  ]);

  useEffect(() => {
    if (token) {
      if (skipPreview) {
        savePreferenceAndJoin();
      } else if (defaultCamera) {
        hmsActions.setVideoSettings({ deviceId: defaultCamera });
      } else {
        preview();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    skipPreview,
    defaultCamera,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
  ]);

  const [checked, setChecked] = useState<boolean>(true);
  return (
    <Container>
      <PreviewTile
        name={name}
        selectedDeviceIDs={selectedDeviceIDs}
        videoInput={videoInput}
      />

      <Flex
        as="form"
        direction="column"
        align="center"
        onSubmit={(e: any) => {
          e.preventDefault();
          savePreferenceAndJoin();
        }}
      >
        <Input
          css={{
            border: "1px solid white !important",
            boxShadow: "none !important",
            backgroundColor: "#003E70",
            color: "white",
            fontFamily: "Brandon_Grotesque",
            fontSize: "16px",
            width: "100% !important",
            margin: "$2",
            borderRadius: "unset !important",
          }}
          autoComplete="email"
          type="text"
          name="email"
          required
          autoFocus={true}
          maxLength={40}
          value={name}
          placeholder="Join with email:"
          aria-placeholder="share email"
          onChange={e => {
            setName(e.target.value);
          }}
          data-testid="preview_name_field"
        />
        <Flex>
          {/*  // backgroundColor: "#003e70", */}
          <Checkbox.Root
            css={{
              margin: "1px",
              border: "none !important",
            }}
            checked={checked}
            onCheckedChange={() => setChecked(!checked)}
          >
            <Checkbox.Indicator>
              <CheckIcon width={14} height={14} />
            </Checkbox.Indicator>
          </Checkbox.Root>
          <Text css={{ fontSize: "14px" }}>
            I agree to SubWire{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="//www.subwire.io/terms-and-conditions"
            >
              terms and conditions
            </a>
            .
          </Text>
        </Flex>
        <Button
          css={{
            color: "black",
            borderColor: "$borderLight",
            textTransform: "uppercase",
            fontSize: "18px",
            margin: "$4",
          }}
          type="submit"
          disabled={!isValidEmail(name) || !enableJoin || !checked}
          data-testid="preview_join_btn"
        >
          Join!
        </Button>
      </Flex>
    </Container>
  );
};

const Container = styled("div", {
  borderRadius: "$2",
  backgroundColor: "$black",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // padding: "0.25rem 0.25rem",
  "@md": {
    borderRadius: "0",
    padding: "0",
    width: "100%",
    height: "100%",
  },
});

const settingStyles = {
  bottom: "10px",
  right: "20px",
};

export default Preview;
