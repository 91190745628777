/**
 * @param {RequestInfo} url
 * @param {RequestInit} options
 * @returns {Promise<Response>}
 */
const fetchWithRetry = async (url: string, options: RequestInit) => {
  const MAX_RETRIES = 4;
  let error = Error("something went wrong");
  for (let i = 0; i < MAX_RETRIES; i++) {
    try {
      return await fetch(url, options);
    } catch (err: any) {
      error = err;
    }
  }
  console.error("Fetch failed after max retries", { url, options });
  throw error;
};

export default async function getVanityUrls(endpoint: any) {
  try {
    const response = await fetchWithRetry(endpoint, {
      method: "GET",
    });

    if (!response.ok) {
      let error = new Error("Request failed!");
      error.message = await response.text();
      throw error;
    }

    const data = !endpoint
      ? {
          wileyg: { roomId: "6373d4cee08863a3f2fbc149", venmo: "wileygaby" },
          subwireceo: {
            roomId: "6373ef13e08863a3f2fbc1f1",
            venmo: "subwireceo",
          },
          alex: { roomId: "62c8702ec42dc78aa5b86e34", venmo: "alex-davidoff" },
          colonelforbin: {
            roomId: "6377f97eea4ced3e875729cc",
            venmo: "alex-davidoff",
          },
        }
      : await response.json();
    // response will be sucess and token is null when url is valid but response
    // domain is not present in 100ms
    if (data === null) {
      throw Error(data.msg);
    }
    return data.body;
  } catch (err) {
    console.error(err);
    throw err;
  }
}
