import axios from "axios";
import { v4 } from "uuid";
import { getTimezone } from "countries-and-timezones";
import Animated_Gif from "gif-transparency";
import { Dispatch, SetStateAction } from "react";

export function shadeColor(color: any, percent: any) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.floor((R * (100 + percent)) / 100);
  G = Math.floor((G * (100 + percent)) / 100);
  B = Math.floor((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

/**
 * TODO: this is currently an O(N**2) function, don't use with peer lists, it's currently
 * being used to find intersection between list of role names where the complexity shouldn't matter much.
 */
export const arrayIntersection = (a?: any[], b?: any[]) => {
  if (a === undefined || b === undefined) {
    return [];
  }
  // ensure "a" is the bigger array
  if (b.length > a.length) {
    let t = b;
    b = a;
    a = t;
  }
  return a.filter(function (e: any) {
    return b?.indexOf(e)! > -1;
  });
};

export const getMetadata = (metadataString: any) => {
  try {
    return metadataString === "" ? {} : JSON.parse(metadataString);
  } catch (error) {
    return {};
  }
};

export const metadataProps = function (peer: any) {
  return {
    isHearted: getMetadata(peer.metadata)?.isHearted,
  };
};

export const mobileChatStyle = {
  position: "absolute",
  bottom: "2.5rem",
  zIndex: 40,
  width: "100%",
  maxWidth: "95vw",
  height: "50vh",
  maxHeight: "50vh",
};

export const isScreenshareSupported = () => {
  return typeof navigator.mediaDevices.getDisplayMedia !== "undefined";
};

export const capture = (stream: any) => {
  const video = document.createElement("video");
  video.playsInline = true; // required for ios in order to prevent fullscreen
  video.muted = true; // required to prevent audio feedback from temporary video

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  video.srcObject = stream;

  return new Promise((resolve, reject) => {
    video.addEventListener("loadeddata", async () => {
      const { videoWidth, videoHeight } = video;

      // Sample a square in the center of video of up to 1080x1080 of center
      const sampleWidth = Math.max(960, videoWidth / 4.0, videoHeight / 4.0);
      const sampleHeight = sampleWidth;

      const resultWidth = 480;
      const resultHeight = resultWidth;

      canvas.width = resultWidth;
      canvas.height = resultHeight;

      // center square of 320x320
      try {
        await video.play();
        if (context) {
          context.drawImage(
            video,
            videoWidth * 0.5 - sampleWidth / 2.0, // halfway minus half of sample size
            videoHeight * 0.5 - sampleHeight / 2.0,
            sampleWidth,
            sampleHeight,
            0,
            0,
            resultWidth,
            resultHeight
          );

          // Watermark
          const text = "";
          context.font = "10px monospace";
          const textWidth = context.measureText(text).width;
          context.globalAlpha = 0.5;
          context.fillStyle = "white";
          context.fillText(
            text,
            canvas.width - textWidth - 10,
            canvas.height - 20
          );
          context.fillStyle = "black";
          context.fillText(
            text,
            canvas.width - textWidth - 10 + 2,
            canvas.height - 20 + 2
          );

          canvas.toBlob(resolve, "image/png");
        }
      } catch (error) {
        reject(error);
      }
    });
  });
};

/**
 * getBase64FromBlob method to convert a blob to base64 for web transport
 * @param file Binary file in blob format
 * @returns Promise to resolve a string into a file: Blob
 */
export const getBase64FromBlob = async (file: any) => {
  return new Promise((resolve: (value: string) => void, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result !== null && typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        console.log("Reader result is not string.");
      }
    };
    reader.onerror = error => reject(error);
  });
};

interface chatMsg {
  fingerprint: string;
  content: string;
  avatar: string;
  sender_id: string;
  sender_name: string;
  popup_uuid: string;
  metadata: string;
  thread_uuid: string;
}
export const postChats = async (msg: chatMsg) => {
  const now = new Date();
  let hotjar_id = "unknown";
  if (window.hj !== undefined && window.hj?.globals) {
    hotjar_id = window.hj?.globals?.get("userId");
  }
  // POST all content to API
  try {
    axios.post("https://api.subwifi.com/chats", {
      key: msg.fingerprint,
      content: msg.content,
      utc_ms: now.getTime() * 1000 + now.getUTCMilliseconds(),
      avatar: msg.avatar,
      sender_id: msg.sender_id,
      sender_name: msg.sender_name,
      popup_uuid: msg.popup_uuid,
      metadata: `{'hotjar_user_id': '${hotjar_id}', 'msg_type': '${msg.metadata}', 'visitor_id': '${msg.fingerprint}'`,
      thread_uuid: msg.thread_uuid,
    });
  } catch (e) {
    console.log(`Could not post chat: ${JSON.stringify(msg)}  because ${e}`);
  }
};

export const registerFingerprint = (fp: any) => {
  let visitor_timezone = "unknown";
  let timezone_offset = null;
  try {
    visitor_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timezone_offset = getTimezone(visitor_timezone)?.utcOffset;
  } catch (e) {
    console.log(
      "Your browser doesn't support Intl and/or retrieving utc offset."
    );
  }
  try {
    axios.post("https://api.subwifi.com/fingerprints", {
      key: fp,
      visitor_id: fp,
      visitor_timezone: visitor_timezone,
      timezone_offset: timezone_offset,
    });
  } catch (error) {
    console.error(`error posting to /fingerprints: ${error}`);
  }
};

export const photoShare = async (
  [pressed, setPressed]: [boolean, Dispatch<SetStateAction<boolean>>],
  localStream: any,
  user: any,
  popup_uuid: string,
  visitor_id: string,
  session_id: string
) =>
  new Promise((res, _rej) => {
    if (pressed) return;

    const images: any = [];

    hotjar_event("share");
    if (localStream) {
      let hotjar_id = "unknown";
      if (window.hj !== undefined && window.hj?.globals) {
        hotjar_id = window.hj?.globals?.get("userId");
      }
      let msgs = [];
      const shutter = setInterval(() => {
        capture(localStream).then(blob => {
          getBase64FromBlob(blob).then((base64: string) => {
            if (images.length < 10) {
              const imgElem = new Image();
              imgElem.src = base64;
              images.push(imgElem);
              const uuid = v4();
              const msg = {
                uuid: uuid,
                thread_uuid: session_id,
                popup_uuid: popup_uuid,
                sender_id: visitor_id,
                sender_name: user,
                avatar: "Bee",
                content: base64,
                metadata: `{'hotjar_user_id': '${hotjar_id}' }`,
              };
              msgs.push(msg);
            } else {
              setPressed(false);
              clearInterval(shutter);
              res(images);
            }
          });
        });
      }, 100);
    } else {
      res([]);
    }
  });

export const create_gif = (images: any, visitor_id: any) =>
  new Promise((res: (value: string) => any, _rej) => {
    const gif = new Animated_Gif();
    gif.setSize(480, 480);
    gif.setDelay(100);
    images.forEach((img: any) => {
      gif.addFrame(img);
    });
    gif.getBase64GIF((image: any) => {
      res(image);
    });
  });

export const hotjar_event = (type: any) => {
  if (!type) return;
  const event = type + "_" + new Date().toISOString().slice(11);
  window.hj("event", event);
};

export const getPopupChatHistory = async (key: string, roomId: string) => {
  if (!key || !roomId) return [];
  try {
    const response = await axios.get(
      "https://api.subwifi.com/chats?key=" + key + "&popup_uuid=" + roomId
    );
    const result = await response.data;
    return result;
  } catch (e) {
    console.log(
      `Error retrieving chat history from room ${roomId} because ${e}`
    );
  }
};
